import {ChangeDetectionStrategy, Component, EventEmitter, Output, signal, WritableSignal,} from '@angular/core';
import {User} from 'src/app/shared/models/user';
import {AuthService} from "../../../../auth/services/auth.service";
import {ThemeService} from "../../../../services/theme.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

interface Option {
  label: string;
  description: string;
  icon: string;
  link: string;
  fragment?: string;
}

@Component({
    selector: 'sc-user-badge',
    templateUrl: './user-badge.component.html',
    styleUrls: ['./user-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserBadgeComponent {
  user: User | undefined;
  showSubmenu = signal<boolean>(false);
  isDark: WritableSignal<boolean> = signal<boolean>(false);
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleTravellerMode: EventEmitter<void> = new EventEmitter<void>();
  options: Option[] = [
    {
      label: 'Profile',
      description: 'View your profile',
      icon: 'bi bi-person',
      link: '/users/me',
    },
    // {
    //   label: 'Companies',
    //   description: 'All my companies',
    //   icon: 'bi bi-briefcase',
    //   link: '/profile',
    // },
    {
      label: 'Settings',
      description: 'Account settings',
      icon: 'bi bi-gear',
      link: '/users/me',
      fragment: 'settings',
    },
    // {
    //   label: 'TravellerMode',
    //   description: 'Enable/disable traveller mode',
    //   icon: 'bi bi-airplane',
    //   link: '/',
    // },
  ];

  constructor(private authService: AuthService, private themeService: ThemeService) {
    this.authService.getUser().pipe(takeUntilDestroyed()).subscribe((user) => {
      this.user = user;
    });
    this.themeService.isDarkMode$.pipe(takeUntilDestroyed()).subscribe((isDark: boolean) => {
      this.isDark.set(isDark);
    })
  }


  toggleTravellerModeClick(): void {
    this.toggleTravellerMode.emit();
  }

  logoutClick(): void {
    this.logout.emit();
  }

}
