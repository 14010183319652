import {ChangeDetectionStrategy, Component, effect, inject, Input, output, signal, WritableSignal} from '@angular/core';
import Swiper, {Autoplay} from "swiper";
import {Translation} from "../../../modules/alerts/alerts.models";
import {LanguageService} from "../../services/language.service";
import {finalize} from "rxjs";
import {Alert} from "../../../shared/models/alert";
import {Router} from "@angular/router";
import {AlertsHeaderService} from "../../services/alerts-header.service";
import {UsersService} from "../../../modules/users/users.service";
import {SectraResponse} from "../../../shared/models/response";
import {AuthRes} from "../../../shared/models/auth";
import {AuthService} from "../../auth/services/auth.service";
import {LoaderService} from "../../services/loader.service";
import {MetaData, Notification} from "../../../shared/models/utils";
import {AlertsService} from "../../../modules/alerts/services/alerts.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

Swiper.use([Autoplay]);

export interface AlertSlide {
  id: number;
  title: string;
  description: string;
  level: number;
}

@Component({
    selector: 'sc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeaderComponent {
  // services
  private readonly alertsService = inject(AlertsService);
  protected readonly languageService = inject(LanguageService);
  private readonly usersService = inject(UsersService);
  private readonly authService = inject(AuthService);
  private readonly loaderService = inject(LoaderService);
  private readonly router = inject(Router);
  private readonly alertsHeader = inject(AlertsHeaderService);


  isUserLoggedRef: WritableSignal<boolean> = signal(false);
  initialAlerts: Alert[] = [];
  alerts: AlertSlide[] = [];
  isLoadingAlert = signal<boolean>(false);
  notificationPage: number = 0;
  notificationsMeta: MetaData | undefined = undefined;
  isLoadingNotification = signal<boolean>(false);

  notifications = signal<Notification[]>([]);

  // inputs and outputs
  logout = output<void>();


  constructor() {
    effect(() => {
      if (!!this.languageService.userLanguage()) {
        this.initSlides();
      }
    });
    this.alertsHeader.onAlertsReload().pipe(takeUntilDestroyed()).subscribe({
      next: () => {
        this.loadAlerts();
      }
    });
  }

  @Input() set isUserLogged(logged: boolean) {
    this.isUserLoggedRef.set(logged);
    if (logged) {
      this.loadAlerts();
      this.isLoadingAlert.set(true);
      this.loadNotification();
    }
    else {
      this.notifications.set([]);
      this.notificationPage = 0;
      this.notificationsMeta = undefined;
    }
  }

  private loadAlerts(): void {
    this.isLoadingAlert.set(true);
    this.alertsService.getBreakingNews().pipe(finalize(() => this.isLoadingAlert.set(false))).subscribe((res) => {
      this.initialAlerts = res.data;
      this.initSlides();
    });
  }

  private initSlides(): void {
    this.alerts = this.initialAlerts.map((alert) => {
      const data = alert.updates.length > 0 ? this.getTranslation(alert.updates[alert.updates.length - 1].translations, this.languageService.userLanguage()) : this.getTranslation(alert.translations, this.languageService.userLanguage());
      return {
        id: alert.id,
        title: data.title,
        description: data.description,
        level: alert.level.id,
      };
    });
  }

  getTranslation(translations: Translation[], language: string): Translation {
    let index: number = translations.findIndex((translation) => translation.language === language);
    if (index === -1) {
      index = translations.findIndex((translation) => translation.language === 'en');
    }
    if (index === -1) {
      index = 0;
    }
    return translations[index];
  }

  logoutClick(): void {
    this.logout.emit();
  }

  async navToAlertDetails(id: number): Promise<void> {
    await this.router.navigate([`/alerts/${id}`]);
  }

  toggleTravellerMode(): void {
    const delay: number = 2000;
    if (!this.authService.user?.travellerMode) {
      this.loaderService.showLoader('Switching to traveller mode...');
    }
    else {
      this.loaderService.showLoader('Switching to company admin mode...');
    }
    this.usersService.switchCompanyAdminToTraveller()
      .pipe(finalize(() => this.loaderService.hideLoader(delay)))
      .subscribe({
        next: (res: SectraResponse<AuthRes>) => {
          if (res.data) {
            this.authService.switchTravellerMode(res.data.user);
            setTimeout(async () => {
              await this.router.navigate(['/']);
            }, delay);
          }
        }
      });
  }

  updateNotificationRead(): void {
    this.usersService.uploadNotificationReadStatus().subscribe();
  }

  loadNotification(): void {
    this.isLoadingNotification.set(true);
    this.notificationPage = this.notificationPage + 1;
    this.usersService.getNotifications(this.notificationPage).pipe(finalize(() => this.isLoadingNotification.set(false)))
      .subscribe((res) => {
        this.notifications.update((notifications) => [...notifications, ...res.data]);
        this.notificationsMeta = res.meta;
      });
  }

  setUserLanguage(language: string): void {
    this.usersService.setUserLanguage(language).subscribe();
  }
}
