<div class="h-screen w-72 p-4" id="sidenav">
  <div class="sc-card flex flex-col items-center !p-2 select-none h-full rounded-lg">

    <a href="/">
      <img [src]="'assets/logos/' + (isDarkMode ? 'logo-light' : 'logo-dark') +'.svg'" alt="logo" class="h-8 my-10"/>
    </a>
    <div class="overflow-y-auto flex flex-col justify-between h-full">
      <ul>
        @if (showMenuItems()) {
          @for (item of sidenavItems(); track $index) {
            <li [roles]="item.roles" isAuthorize>
              @if (item.spacing) {
                <div class="sc-divider my-4 w-10/12"></div>
              }
              <div (click)="itemClickedHandler(item)"
                   [ngClass]="[(currentRoute.includes(item.route) && !item.subItems) || (currentRoute === '/' && item.route ==='/dashboard') ? 'active': isSubRouteActive(item) ? 'sc-text-primary' : '']"
                   class="px-4 py-1 sidenav-item sc-hover relative sc-text-default">
                <i [ngClass]="item.icon" class="text-lg"></i>
                <span class="text-sm">{{ "sidenav." + item.name.toLowerCase() | transloco }}</span>
                <!-- TODO justify self end no absolute-->
                @if (item.subItems) {
                  <i [ngClass]="[item.clicked ? 'rotate-90' : 'rotate-0',isSubRouteActive(item) ? 'sc-text-primary' : 'sc-text-default']"
                     class="bi bi-chevron-right absolute right-4 duration-200"
                  ></i>
                }
              </div>
              @if (item.subItems) {
                <ul #submenu [style.height.px]="item.clicked ? submenu.scrollHeight : 0"
                    class="submenu">
                  @for (subItem of item.subItems; track $index) {
                    <li (click)="navToSubRoute(item, subItem)" [ngClass]="{ 'active': currentRoute.includes(subItem.route)}"
                        class="px-6 py-1 sidenav-item sc-hover outline-0 ring-0 sc-text-default">
                      <i [ngClass]="subItem.icon" class="text-lg bi"></i>
                      <span class="text-sm">{{ "sidenav.subItems." + subItem.name.toLowerCase() | transloco }}</span>
                    </li>
                  }
                </ul>
              }
            </li>
          }
        }
      </ul>
      <div class="flex flex-col items-center gap-1 py-2 w-full">
        <div class="sc-divider my-4 w-10/12"></div>
        <sc-theme-toggle></sc-theme-toggle>
        <div class="text-2xs mx-auto sc-text-default">Powered by EY</div>
        <div class="text-xs mx-auto sc-text-default">v{{ version() }}</div>
        <div [ngClass]="isStaging()?'!bg-warn !text-black' : '!bg-danger !text-white' "
             class="sc-badge !text-xs font-bold tracking-wider !px-6 mt-2" isAuthorize [roles]="['1','2']">
          {{ isStaging() ? 'STAGING' : 'PROD' }}
        </div>
      </div>
    </div>
  </div>
</div>

