<div [ngClass]="{'opacity-20': isDisabled}" class="inline-flex items-center">
  <label

    class="relative flex items-center rounded-full"
    data-ripple-dark="true"
    for="checkbox"
  >
    <input
      (change)="change($event)"
      [checked]="isChecked"
      [disabled]="isDisabled"
      [ngClass]="isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
      class="before:content[''] peer relative h-5 w-5 appearance-none bg-white rounded-md border border-dark/10 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-dark/10 checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
      id="checkbox"
      type="checkbox"
    />
    <div
      class="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
      <svg
        class="h-3.5 w-3.5"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          fill-rule="evenodd"
        ></path>
      </svg>
    </div>
  </label>
</div>
