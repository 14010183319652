import {inject, Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {Observable, share} from "rxjs";
import {SectraResponse} from "../../../shared/models/response";
import {Alert, AlertCreate, AlertUpdateCreate} from "../../../shared/models/alert";
import {EnumRoles} from "../../../core/auth/models/roles.model";
import {UserMainData} from "../../../shared/models/user";
import {AlertsListFilter} from "../alerts.models";
import {ErrorService} from "../../../core/services/error.service";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertsHeaderService} from "../../../core/services/alerts-header.service";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private readonly basePath = `${environment.API_BASEURL}/${environment.API_VERSION}`;
  private readonly rolePath = 'common';
  private readonly http = inject(HttpClient)
  private readonly toastController = inject(ToastrService);
  private readonly errorService = inject(ErrorService);
  private readonly router = inject(Router);
  private readonly location = inject(Location);
  private readonly alertHeaderService = inject(AlertsHeaderService);


  constructor() {
  }


  // ALERTS
  getById(id: number): Observable<SectraResponse<Alert>> {
    const req$ = this.http.get<SectraResponse<Alert>>(`${this.basePath}/${this.rolePath}/alerts/${id}`).pipe(share());
    req$.subscribe({
        error: (err) => {
          this.errorService.showErrors(err);
          this.router.navigate(['/alerts']);
        },
      }
    );
    return req$;
  }

  getAll(filters: AlertsListFilter): Observable<SectraResponse<Alert[]>> {
    const {page, limit, sort, searchQuery, level, range, countries, types, status} = filters;
    const req$ = this.http.get<SectraResponse<Alert[]>>(`${this.basePath}/${this.rolePath}/alerts/${EnumRoles.All}/${page}/${limit}/${sort}/${searchQuery ? '&search=' + searchQuery : ''}${level && level !== '*' ? '&levelId=' + level : ''}${range?.length ? '&range=' + new Date(range[0]).valueOf() + '-' + (range[1] ? new Date(range[1]).valueOf() : new Date(range[0]).valueOf()) : ''}${countries?.length ? '&countries=' + countries.map(c => c) : ''}${types && types.length > 0 ? '&types=' + types.map(c => c) : ''}${!!status && status !== '*' ? '&status=' + status : ''}`).pipe(share());
    req$.subscribe({
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  createOne(alert: AlertCreate): Observable<SectraResponse<Alert>> {
    const req$ = this.http.post<SectraResponse<Alert>>(`${this.basePath}/admin/alerts`, alert).pipe(share());
    req$.subscribe({
        next: (res) => {
          this.toastController.success('Alert created successfully');
          this.alertHeaderService.triggerAlertsReload();
          this.location.back();
        },
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  updateOne(id: number, alert: AlertCreate): Observable<SectraResponse<Alert>> {
    const req$ = this.http.patch<SectraResponse<Alert>>(`${this.basePath}/admin/alerts/${id}`, alert).pipe(share());
    req$.subscribe({
        next: (res) => {
          this.toastController.success('Alert updated successfully');
          this.alertHeaderService.triggerAlertsReload();
          this.location.back();
        },
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  deleteOne(id: number): Observable<SectraResponse<void>> {
    const req$ = this.http.delete<SectraResponse<void>>(`${this.basePath}/admin/alerts/${id}`).pipe(share());
    req$.subscribe({
        next: () => {
          this.toastController.success('Alert deleted successfully');
          this.alertHeaderService.triggerAlertsReload();
          this.router.navigate(['/alerts']);
        },
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  // Alert update
  addUpdate(id: number, update: AlertUpdateCreate): Observable<SectraResponse<Alert>> {
    const req$ = this.http.post<SectraResponse<Alert>>(`${this.basePath}/admin/alerts/${id}/updates`, update).pipe(share());
    req$.subscribe({
        next: (res) => {
          this.toastController.success('Alert updated added successfully');
          this.alertHeaderService.triggerAlertsReload();
        },
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  deleteUpdate(alertId: number, updateId: number): Observable<SectraResponse<Alert>> {
    const req$ = this.http.delete<SectraResponse<Alert>>(`${this.basePath}/admin/alerts/${alertId}/updates/${updateId}`).pipe(share());
    req$.subscribe({
        next: (res) => {
          this.toastController.success('Alert updated deleted successfully');
          this.alertHeaderService.triggerAlertsReload();
        },
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  editUpdate(alertId: number, updateId: number, update: AlertUpdateCreate): Observable<SectraResponse<Alert>> {
    const req$ = this.http.patch<SectraResponse<Alert>>(`${this.basePath}/admin/alerts/${alertId}/updates/${updateId}`, update).pipe(share());
    req$.subscribe({
        next: (res) => {
          this.toastController.success('Alert updated added successfully');
          this.alertHeaderService.triggerAlertsReload();
        },
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  // Impacted users
  reloadImpactedUsers(alertId: number): Observable<SectraResponse<void>> {
    // return this.http.get<SectraResponse<void>>(`${this.basePath}/${this.rolePath}/alerts/${alertId}/reload-impacted-users`);
    const req$ = this.http.get<SectraResponse<void>>(`${this.basePath}/admin/alerts/impactedUserReload/${alertId}`).pipe(share());
    req$.subscribe({
        next: () => {
          this.toastController.success('Impacted users reloaded successfully');
        },
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;

  }

  getImpactedUsers(id: number): Observable<SectraResponse<UserMainData[]>> {
    const req$ = this.http.get<SectraResponse<UserMainData[]>>(`${this.basePath}/${this.rolePath}/alerts/${id}/impacted-users`).pipe(share());
    req$.subscribe({
        error: (err) => {
          this.errorService.showErrors(err);
        },
      }
    );
    return req$;
  }

  // Breaking news
  getBreakingNews(): Observable<SectraResponse<Alert[]>> {
    return this.http.get<SectraResponse<Alert[]>>(`${this.basePath}/${this.rolePath}/alerts/breaking-news`);
  }

}
