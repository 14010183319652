import { HttpClient } from '@angular/common/http';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, signal, WritableSignal} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {finalize, Subject, take, takeUntil} from 'rxjs';
import {AuthService} from 'src/app/core/auth/services/auth.service';
import {AuthRes} from "../../../../shared/models/auth";
import {SectraResponse} from 'src/app/shared/models/response';
import {AlertsHeaderService} from "../../../services/alerts-header.service";
import {ThemeService} from "../../../services/theme.service";


interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
    selector: 'sc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoginComponent implements OnDestroy, AfterViewInit {
  loginForm: FormGroup = new FormGroup<LoginForm>({
    email: new FormControl<string | null>(null, [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl<string | null>(null, [Validators.required]),
  });
  badCredentials: WritableSignal<boolean> = signal(false);
  isLoading: WritableSignal<boolean> = signal(false);
  isDarkMode: WritableSignal<boolean> = signal(true);

  private unsubscribeAll$ = new Subject<void>();


  constructor(
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly toastController: ToastrService,
    private readonly alertHeaderService: AlertsHeaderService,
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
  ) {

    this.loginForm.valueChanges
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe({
        next: (value) => {
          if (!!value) this.badCredentials.set(false);
        },
      });

    this.themeService.isDarkMode$
      .pipe(take(1))
      .subscribe((isDarkMode: boolean) => {
        this.isDarkMode.set(isDarkMode);
      });
  }

  ngAfterViewInit() {
    // this.loginForm.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  login() {
    this.isLoading.set(true);
    this.authService.login(this.loginForm.value)
      .pipe(
        finalize(() => this.isLoading.set(false))
      )
      .subscribe({
        next: (res: SectraResponse<AuthRes>) => {
          this.toastController.success(`Welcome ${res.data.user.fullName}`);
          this.router.navigate(['/']);
          this.alertHeaderService.triggerAlertsReload();
        },
        error: (error) => {
          if (error.status === 401) {
            this.badCredentials.set(true);
          }
          console.error(error);
        },
      });
  }

  // refresh() {
  //   this.isLoading = true;
  //   this.authService.login(this.loginForm.value).subscribe({
  //     next: (res: SectraResponse<AuthRes>) => {
  //       this.router.navigate(['/dashboard']);
  //     },
  //     error: (error) => {
  //       if (error.status === 401) {
  //         this.badCredentials = true;
  //       }
  //       console.error(error);
  //     },
  //   });
  // }
}
