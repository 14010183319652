import {ChangeDetectionStrategy, Component, inject, OnDestroy, signal, WritableSignal} from '@angular/core';
import {filter, fromEvent, Subject, takeUntil} from "rxjs";
import {AuthService} from "./core/auth/services/auth.service";
import {ThemeService} from "./core/services/theme.service";
import {SocketService} from "./core/services/socket.service";
import {LoaderService} from "./core/services/loader.service";
import {NavigationStart, Router} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AppState} from "./reducers";
import {select, Store} from "@ngrx/store";
import {isLoggedIn, isRefreshingToken} from "./core/auth/auth.selectors";
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {ModalControllerService} from "./core/services/modal-controller.service";

// import {FirebaseService} from "./core/services/firebase.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent implements OnDestroy {
  title = 'Sectra24';
  isUserLogged: WritableSignal<boolean> = signal<boolean>(false);
  private isOffline: WritableSignal<boolean> = signal<boolean>(false);
  isLoaderVisible: WritableSignal<boolean> = signal<boolean>(false);
  isPrivacyPolicyPage: WritableSignal<boolean> = signal<boolean>(false);

  isRecoveringSession: WritableSignal<boolean> = signal<boolean>(false);

  private readonly unsubscribeAll$ = new Subject<void>();

  private updates = inject<SwUpdate>(SwUpdate);
  private readonly modalController = inject(ModalControllerService);

  constructor(
    private readonly themeService: ThemeService,
    private readonly authService: AuthService,
    private readonly socketService: SocketService,
    private readonly loaderService: LoaderService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {
    this.updates.checkForUpdate();
    this.loaderService.isLoading().pipe(takeUntilDestroyed()).subscribe({
      next: (isLoading) => {
        this.isLoaderVisible.set(isLoading);
      }
    });
    this.router.events.pipe(filter(event => event instanceof NavigationStart), takeUntilDestroyed()).subscribe({
      next: (event: any) => {
        this.isPrivacyPolicyPage.set(event.url === '/privacy-policy');
      }
    });

    this.themeService.checkActivateTheme();
    fromEvent(window, 'offline').pipe(takeUntilDestroyed()).subscribe(() => {
      this.isOffline.set(true);
    });
    fromEvent(window, 'online').pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.isOffline()) {
        this.socketService.connectToSocket();
      }
      this.isOffline.set(false);
    });


    this.store.pipe(select(isRefreshingToken)).subscribe({
      next: (isRefreshing) => {
        if (isRefreshing) {
          this.isRecoveringSession.set(true);
        }
        else {
          this.isRecoveringSession.set(false);
        }
      }
    });

    this.store.pipe(takeUntil(this.unsubscribeAll$), select((isLoggedIn))) // select è come il map di rxjs
      .subscribe({
        next: (isLogged) => {
          if (isLogged) {
            this.socketService.connectToSocket();
          }
          else {
            this.socketService.disconnectFromSocket();
          }
          this.isUserLogged.set(isLogged)
        }
      });

    this.updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        this.modalController.open('upload-app');
      });
  }

  // ngOnInit(): void {
  //   if (window.top && window.top !== window.self) {
  //     window.top.location = window.self.location;
  //   }
  // }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  logoutUser(): void {
    this.authService.logout();
  }

  updatePwa(): void {
    window.location.reload();
  }
}
