<div *ngIf="user" class="relative">
  <button class="relative sc-card !p-1" id="user-profile-submenu-button" (click)="showSubmenu.set(true)" submenuToggle
          (onClickOutside)="showSubmenu.set(false)">
    <div
      class="relative backdrop-blur-sm p-2 bg-light-secondary dark:bg-dark-secondary border-2 border-light dark:border-dark-secondary rounded-full w-8 h-8 flex justify-center items-center"
    >
      <span class="font-medium text-gray-600 dark:text-gray-300 text-sm">{{
          user.initials
        }}</span>
    </div>
    <img *ngIf="user.city?.country?.countryCode && user.city"
         [src]="
        'assets/images/icons/flags/' +
        user.city.country.countryCode!.toLowerCase() +
        '.svg'
      "
         alt="flag"
         class="rounded-full h-4 w-4 absolute -bottom-1 -right-1 border-2 border-light dark:border-dark-secondary"
    />
    <div *ngIf="user.travellerMode" class="flex items-center justify-center group">
      <div
        class="h-6 w-6 flex justify-center items-center sc-text-default rounded-full absolute -bottom-2 -left-1 border-2 border-light dark:border-dark-secondary z-10 bg-light dark:bg-dark">
        <i class="bi-airplane text-xs"></i>
      </div>
      <div class="animate-ping bg-primary opacity-90 h-5 w-5 rounded-full absolute -left-0.5"></div>
      <div class="tooltip-bottom-to-top w-[150px] right-4 z-[99]">
        <div class="sc-card-reverse !p-2 text-sm sc-text-default">Traveller mode</div>
      </div>
    </div>
  </button>
  <!--  <pre>{{showSubmenu}}</pre>-->
  <div *ngIf="showSubmenu()" class="sc-card !p-0 text-dark dark:text-white absolute right-0 mt-1 w-[16rem] z-[99]">
    @if (!!user) {
      <div class="flex items-center gap-2 p-4">
        <sc-avatar
          [country]="user.city && user.city.country && user.city.country.countryCode ? user.city.country.countryCode.toLowerCase() : ''"
          [initials]="user!.initials"
        ></sc-avatar>
        <div class="flex flex-col">
          <span *ngIf="user?.fullName" class="text-sm">{{ user.fullName }}</span>
          <span *ngIf="!!user?.role?.name" class="text-xs">{{ user.role.name }}</span>
        </div>
      </div>
    }
    <div *ngFor="let option of options; let i = index" [fragment]="!!option.fragment ? option.fragment : ''"
         [routerLink]="option.link" class="options-container">
      <div class="option-container sc-hover">
        <i [ngClass]="option.icon" class="text-xl"></i>
        <div class="flex flex-col gap-1">
          <span class="text-sm">
            {{ "header.userBadge." + option.label.toLowerCase() + ".label"| transloco }}
          </span>
          <span class="text-xs font-thin">
            {{ "header.userBadge." + option.label.toLowerCase() + ".description"| transloco }}
          </span>
        </div>
      </div>
    </div>
    <div (click)="toggleTravellerModeClick()" *ngIf="user.travellerMode || user.role.id==='3'"
         class="options-container relative">
      <div class="option-container !gap-2 sc-hover">
        <img [src]="'assets/images/svg/switch-role-' + (isDark() ? 'light' : 'dark') + '.svg'" class="h-6 w-6"/>
        <div class="flex flex-col gap-1">
          <span class="text-sm">
            {{ ('header.userBadge.travellerMode' + (user && user.travellerMode ? '.companyAdmin.' : '.traveller.') + 'description')| transloco }}
          </span>
          <span class="text-xs font-thin">
            {{ ('header.userBadge.travellerMode' + (user && user.travellerMode ? '.companyAdmin.' : '.traveller.') + 'description')| transloco }}
          </span>
        </div>
      </div>
      <!--      <div class="absolute scale-50 right-0 bottom-[35px]">-->
      <!--        <sc-toggle-->
      <!--          (onChange)="toggleTravellerModeClick()"-->
      <!--          [checked]="user.travellerMode ? user.travellerMode : false"-->
      <!--          color="peer-checked:!bg-success dark:peer-checked:!bg-success !bg-danger"-->
      <!--        ></sc-toggle>-->
      <!--      </div>-->
    </div>


    <div class="text-center p-4">
      <button (click)="logoutClick()" class="sc-btn-primary w-full">
        {{ "header.userBadge.logout" | transloco }}
      </button>
    </div>
  </div>
</div>
