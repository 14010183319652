import {ChangeDetectionStrategy, Component, effect, inject, output, signal} from '@angular/core';
import {LanguageService} from 'src/app/core/services/language.service';
import {LabelValue} from 'src/app/shared/models/utils';

@Component({
    selector: 'sc-language-handler',
    templateUrl: './language-handler.component.html',
    styleUrls: ['./language-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LanguageHandlerComponent {
  // services
  protected readonly languageServices = inject(LanguageService);

  // internals
  showSubmenu = signal<boolean>(false);
  // for new language remember to di import localeEn from '@angular/common/locales/en'; to app module
  availableLanguages: LabelValue[] = [
    {
      label: 'english',
      value: 'en',
    },
    {
      label: 'italiano',
      value: 'it',
    },
    // {
    //   label: 'español',
    //   value: 'en',
    // },
    // {
    //   label: 'deutsch',
    //   value: 'en',
    // },
    // {
    //   label: 'français',
    //   value: 'en',
    // },
  ];

  // outputs
  onLanguageChange = output<string>();

  constructor() {
    effect(() => {
      if (!!this.languageServices.userLanguage()) {
        this.onLanguageChange.emit(this.languageServices.userLanguage()!);
      }
    });
  }

  setUserLanguage(language: string) {
    this.languageServices.changeUserLanguage(language as 'en' | 'it');
    this.showSubmenu.set(false);
  }

}
