<section class="h-full w-full flex justify-center">

  <div class="w-2/3 h-full hidden md:flex items-center px-[5%] xl:px-[10%] 2xl:px-[15%]">
    <img [src]="'assets/images/components/' +  (isDarkMode() ? 'login-dark' : 'login-light') +'.png'"
         class="object-contain">
  </div>
  <div class="md:w-1/3 h-full w-full flex items-center justify-center md:login-pr pl-4 md:pl-0">
    <div
      class="w-full sc-text-default max-w-sm"
    >
      <div class="text-center pb-12">
        <img [src]="'assets/logos/' + (isDarkMode() ? 'logo-light' : 'logo-dark') +'.svg'"
             alt="logo" class="mx-auto w-60"/>
        <div class="mt-4">Travel risk management</div>
      </div>
      <div class="text-dark dark:text-primary pb-6 text-3xl">
        {{ 'auth.login' | transloco }}
      </div>
      <form (ngSubmit)="login()" [formGroup]="loginForm" autocomplete="off" class="flex flex-col gap-4">
        <div class="sc-input-container">
          <label>Email</label>
          <div class="py-2 flex items-center relative">
            <i
              class="bi bi-person dark:text-white text-black absolute text-xl left-3"
            ></i>
            <sc-input
              [formControl]="$any(loginForm)?.controls['email']"
              [type]="'email'" class="w-full" classes="!pl-10 !py-4 !text-sm !font-medium"
              id="email"
            ></sc-input>
          </div>
        </div>
        <div class="sc-input-container">
          <label>Password</label>
          <div class="py-2 flex items-center relative">
            <i class="bi bi-lock dark:text-white text-black absolute text-xl left-3"></i>
            <sc-input
              [formControl]="$any(loginForm)?.controls['password']"
              [type]="'password'" class="w-full" classes="!pl-10 !py-4 !text-sm !font-medium"
              id="password"
              placeholder="Password"
            ></sc-input>
          </div>
        </div>

        <div *ngIf="badCredentials()" class="text-center">
          <div class="text-danger text-sm">{{ 'auth.invalidCredentials' | transloco }}</div>
        </div>

        <div class="flex items-center justify-between gap-2 py-4">
          <!--          <input class="sc-input h-4 w-4 !min-w-0 ml-2" type="checkbox"/>-->
          <div class="flex items-center gap-2">
            <div class="scale-75">
              <sc-toggle
                color="peer-checked:bg-primary !bg-gray-200 dark:!bg-dark-secondary"></sc-toggle>
            </div>
            <span class="text-gray-500 text-sm">{{ 'auth.rememberMe' | transloco }}</span>
          </div>
          <a class="!text-gray-500 text-sm" routerLink="/reset-password">{{ 'auth.forgotPassword' | transloco }}</a>
        </div>
        <div class="flex items-center justify-center gap-2 py-2">
          <button
            [disabled]="loginForm.invalid || isLoading()"
            class="sc-btn-primary-static w-full !px-16 !py-3 flex items-center justify-center"
          >
            <ng-container *ngIf="!isLoading(); else spinnerContainer">
              <span class="text-sm">{{ 'common.confirm' | transloco }}</span>
            </ng-container>
            <ng-template #spinnerContainer>
              <sc-spinner [classes]="'h-5 w-5'"></sc-spinner>
            </ng-template>
          </button>

          <!--          <div class="flex gap-2 ml-2">-->
          <!--            <span>or</span>-->
          <!--            <a class="text-base" href="">Create</a>-->
          <!--            <span>an account</span>-->
          <!--          </div>-->
        </div>
        <div class="text-xs mx-auto text-gray-400 pt-10">
          Powered by EY
        </div>
      </form>
    </div>
  </div>


  <img [src]="'assets/logos/' + (isDarkMode() ? 'ey-light' : 'ey-dark') +'.png'" alt="logo"
       class="w-[12%] md:w-[5%] absolute left-10 bottom-10"/>
</section>

